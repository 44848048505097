import { LanguageSelect } from "./LanguageSelect";
import iconSrc from "../../../icons/swap.svg";

export function LanguageControls({
  firstLanguage,
  secondLanguage,
  onChangeFirstLanguage,
  onChangeSecondLanguage,
}) {
  const onSwap = () => {
    onChangeFirstLanguage(secondLanguage);
    onChangeSecondLanguage(firstLanguage);
  };
  return (
    <div className={"flex flex-row justify-around gap-6 py-6 items-center"}>
      <LanguageSelect
        value={firstLanguage}
        onChange={onChangeFirstLanguage}
        filter={(languages) => languages}
      />
      <img
        className={"w-6 h-6 cursor-pointer"}
        src={iconSrc}
        alt={"swap"}
        onClick={onSwap}
      />
      <LanguageSelect
        value={secondLanguage}
        onChange={onChangeSecondLanguage}
        filter={(languages) => {
          const newLanguages = { ...languages };

          delete newLanguages["autodetect"];

          return newLanguages;
        }}
      />
    </div>
  );
}
