import { CardList } from "../CardList";
import { AppSection } from "../AppSection";
import { useSelector } from "react-redux";
import iconSrc from "../../icons/saved.svg";

export function SavedSection() {
  const cards = useSelector((state) =>
    state.cards.list.filter(
      (card) => card.saved && card.id !== state.cards.primaryCardId
    )
  );

  if (cards.length === 0) {
    return null;
  }

  return (
    <AppSection title={"Saved"} iconSrc={iconSrc}>
      <CardList>{cards}</CardList>
    </AppSection>
  );
}
