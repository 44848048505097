import { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useActiveQRCode } from "../../../store/hooks";
import {
  changeCardValues,
  changeSavedStatus,
  makeQRCodeActive,
  resetQRCodeActive,
} from "../../../store/cards";
import { CardButton } from "../CardButton";
import bookmarkFilledIconSrc from "./bookmark-filled.svg";
import bookmarkEmptyIconSrc from "./bookmark-empty.svg";
import shareIconSrc from "./share.svg";

const EDIT_TYPE = "edit";
const QR_CODE_TYPE = "qr-code";
const PASSIVE_TYPE = "passive";

export function Card({
  title,
  id,
  isNeedEdit,
  saved,
  EditContent,
  iconSrc,
  editedDate,
  PassiveContent,
  QRCodeContent,
  isEditButtonVisible = true,
  isShare,
  ...props
}) {
  const formRef = useRef(null);
  const qrRef = useRef(null);
  const dispatch = useDispatch();
  const [isValidEdit, setIsValidEdit] = useState(false);
  const [type, setType] = useState(() => {
    if (isNeedEdit) {
      return EDIT_TYPE;
    }

    return PASSIVE_TYPE;
  });

  const onActiveQRCodeChange = useCallback(
    (isActive) => {
      if (isActive) {
        setType(QR_CODE_TYPE);
      } else if (isNeedEdit) {
        setType(EDIT_TYPE);
      } else {
        setType(PASSIVE_TYPE);
      }
    },
    [setType, isNeedEdit]
  );
  useActiveQRCode(id, onActiveQRCodeChange);

  let content;
  let buttons = [];

  const onQRCode = () => {
    setType(QR_CODE_TYPE);
    dispatch(makeQRCodeActive(id));
  };

  const onSubmit = (e) => {
    if (formRef.current) {
      const values = formRef.current.getValues();
      dispatch(changeCardValues({ id, values }));
      dispatch(resetQRCodeActive());
      onQRCode();
    }
  };

  const shareButton = (
    <CardButton
      className={"w-12 h-12"}
      key={"second"}
      onClick={() => {
        qrRef.current?.shareQRCode();
      }}
    >
      <img
        src={shareIconSrc}
        alt={"bookmark"}
        className={saved ? "w-20 max-w-fit" : ""}
      />
    </CardButton>
  );

  const bookmarkButton = (
    <CardButton
      className={"w-12 h-12"}
      key={"third"}
      onClick={() => {
        dispatch(changeSavedStatus({ id, isSaved: !saved }));
      }}
    >
      <img
        src={saved ? bookmarkFilledIconSrc : bookmarkEmptyIconSrc}
        alt={"share"}
        className={saved ? "w-20 max-w-fit" : ""}
      />
    </CardButton>
  );

  const onEdit = () => {
    setType(EDIT_TYPE);
  };

  switch (type) {
    case EDIT_TYPE: {
      content = (
        <EditContent
          id={id}
          editedDate={editedDate}
          ref={formRef}
          onValidChange={setIsValidEdit}
          onSubmit={onSubmit}
          {...props}
        />
      );

      if (isEditButtonVisible) {
        buttons = [
          <CardButton
            disabled={!isValidEdit}
            key={"first"}
            className={"flex-1"}
            onClick={onSubmit}
            type={"submit"}
          >
            Get QR code
          </CardButton>,
        ];
      }
      break;
    }
    case QR_CODE_TYPE: {
      content = (
        <QRCodeContent
          id={id}
          ref={qrRef}
          editedDate={editedDate}
          onEdit={onEdit}
          {...props}
        />
      );
      buttons = [
        <CardButton
          key={"first"}
          className={"flex-1"}
          onClick={() => {
            setType(PASSIVE_TYPE);
            dispatch(resetQRCodeActive());
          }}
        >
          Hide QR code
        </CardButton>,
        isShare ? shareButton : null,
        bookmarkButton,
      ];
      break;
    }
    default: {
      content = (
        <PassiveContent
          onQRCode={onQRCode}
          editedDate={editedDate}
          id={id}
          onEdit={onEdit}
          {...props}
        />
      );
      buttons = [
        <CardButton className={"flex-1"} key={"first"} onClick={onQRCode}>
          Get QR code
        </CardButton>,
        bookmarkButton,
      ];
      break;
    }
  }

  return (
    <div className={"bg-white rounded-2xl px-4 pt-4 pb-5"}>
      <header className={"flex gap-2 items-center"}>
        <img className={"w-4 h-4"} src={iconSrc} alt={"icon"} />
        <h3 className={"font-bold"}>{title}</h3>
      </header>
      <form
        autoComplete={"off"}
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(e);
        }}
      >
        <div>{content}</div>
        {buttons.length > 0 && (
          <footer className={"flex flex-row gap-2.5 justify-between h-12"}>
            {buttons}
          </footer>
        )}
      </form>
    </div>
  );
}
