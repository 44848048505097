import voiceIcon from "../../../../icons/voice.svg";
import voiceRecordIcon from "../../../../icons/voice-record.svg";
import { useEffect, useRef, useState } from "react";

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;

export const SpeechToVoiceButton = ({ onChange }) => {
  const [isListening, setIsListening] = useState(false);
  const recognitionRef = useRef();

  useEffect(() => {
    if (!SpeechRecognition) {
      return;
    }

    recognitionRef.current = new SpeechRecognition();

    recognitionRef.current.lang = navigator.language;
    recognitionRef.current.interimResults = false;
    recognitionRef.current.maxAlternatives = 1;

    recognitionRef.current.onstart = function (event) {
      setIsListening(true);
    };

    recognitionRef.current.onend = function (event) {
      setIsListening(false);
    };

    recognitionRef.current.onresult = function (event) {
      const speechResult = event.results[0][0].transcript.toLowerCase();

      onChange?.(speechResult);
    };

    recognitionRef.current.onspeechend = function () {
      recognitionRef.current?.stop();
    };

    recognitionRef.current.onerror = function (event) {
      console.error(event);
      setIsListening(false);
    };

    return () => {
      recognitionRef.current.abort();
      recognitionRef.current = null;
    };
  }, [onChange]);

  if (!SpeechRecognition) {
    return null;
  }

  const onClick = () => {
    if (isListening) {
      recognitionRef.current?.stop();
    } else {
      recognitionRef.current?.start();
    }
  };

  return (
    <button
      onClick={onClick}
      className={`p-2 max-h-full border-[#7030A0] border-[1.5px] rounded-xl ${
        isListening ? "bg-[#D32F2F] animate-pulse border-[#D32F2F]" : ""
      }`}
      type={"button"}
    >
      <img src={isListening ? voiceRecordIcon : voiceIcon} alt={"microphone"} />
    </button>
  );
};
