import { QRCode } from "../QRCode";
import { LanguageControls } from "./LanguageControls";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { changeCardValues } from "../../../store/cards";

export const QRCodeContent = forwardRef(
  (
    {
      id,
      firstLanguage: initialFirstLanguage,
      secondLanguage: initialSecondLanguage,
    },
    ref
  ) => {
    const qrCodeRef = useRef();
    useImperativeHandle(
      ref,
      () => ({
        shareQRCode() {
          qrCodeRef.current?.shareQRCode();
        },
      }),
      []
    );
    const [firstLanguage, setFirstLanguage] = useState(initialFirstLanguage);
    const [secondLanguage, setSecondLanguage] = useState(initialSecondLanguage);

    const sourceLanguage =
      firstLanguage !== "autodetect" ? `S:${firstLanguage};` : "";
    const text = `TR:${sourceLanguage}T:${secondLanguage};;`;

    const dispatch = useDispatch();

    useEffect(() => {
      if (
        initialFirstLanguage !== firstLanguage ||
        initialSecondLanguage !== secondLanguage
      ) {
        dispatch(
          changeCardValues({ id, values: { firstLanguage, secondLanguage } })
        );
      }
    }, [
      id,
      firstLanguage,
      secondLanguage,
      dispatch,
      initialFirstLanguage,
      initialSecondLanguage,
    ]);

    return (
      <div className={"flex flex-col"}>
        <LanguageControls
          firstLanguage={firstLanguage}
          onChangeFirstLanguage={setFirstLanguage}
          onChangeSecondLanguage={setSecondLanguage}
          secondLanguage={secondLanguage}
        />
        <div className={"flex justify-center"}>
          <QRCode ref={qrCodeRef} text={text} />
        </div>
      </div>
    );
  }
);
