import editIcon from "./edit.svg";

export const MapPreview = ({ name, address, withIcon }) => {
  return (
    <div className={"py-5 px-10 rounded-2xl bg-gray-200 relative"}>
      <div className={"text-[#161616] text-base break-all"}>{name}</div>
      <div className={"text-black/[.66] text-base break-all"}>{address}</div>
      {withIcon && (
        <img
          className={"absolute top-1/2 -translate-y-1/2 right-4"}
          src={editIcon}
          alt={"edit icon"}
        />
      )}
    </div>
  );
};
