import { CardInput } from "../inputs/CardInput";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { CardRadioButton } from "../inputs/CardRadioButton";

const RADIO_BUTTONS = [
  {
    title: "None",
    value: "NONE",
  },
  {
    title: "WEP",
    value: "WEP",
  },
  {
    title: "WPA/WPA2",
    value: "WPA",
  },
];

export const EditContent = forwardRef(
  (
    {
      networkName: initialNetworkName = "",
      password: initialPassword = "",
      encryption: initialEncryption = "wpa",
      onValidChange,
    },
    ref
  ) => {
    const [networkName, setNetworkName] = useState(initialNetworkName);
    const [password, setPassword] = useState(initialPassword);
    const [encryption, setEncryption] = useState(initialEncryption);

    useEffect(() => {
      if (networkName && password && encryption !== 'NONE' || (networkName && encryption === "NONE")) {
        onValidChange(true);
      } else {
        onValidChange(false);
      }
    }, [networkName, password, encryption]);

    useImperativeHandle(
      ref,
      () => ({
        getValues() {
          return {
            networkName,
            password,
            encryption,
          };
        },
      }),
      [networkName, password, encryption]
    );

    const onChange = (setter) => (value) => {
      setter(value);
    };

    return (
      <div className={"flex flex-col gap-6 py-6"}>
        <div className={""}>
          <div className={"text-sm text-black mb-2"}>Network Name</div>
          <CardInput
            autoFocus
            maxLength={32}
            required
            placeholder={"SSID"}
            value={networkName}
            type={"text"}
            onChange={onChange(setNetworkName)}
          />
        </div>
        <div className={""}>
          <div className={"text-sm text-black mb-2"}>Password</div>
          <CardInput
            required
            maxLength={32}
            onChange={onChange(setPassword)}
            placeholder={"Password"}
            value={password}
            type={"password"}
          />
        </div>
        <div>
          <div className={"text-sm text-black mb-2"}>Encryption</div>
          <ul className={"flex gap-8"}>
            {RADIO_BUTTONS.map((button, index) => (
              <li key={index}>
                <CardRadioButton
                  value={button.value}
                  checked={encryption === button.value}
                  label={button.title}
                  key={button.value}
                  onChange={onChange(setEncryption)}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
);
