import { MapPreview } from "./MapPreview";

export function PassiveContent({ query, name, address, onEdit }) {
  return (
    <div className={"flex flex-col gap-6 py-6"}>
      <div className={"cursor-pointer"} onClick={onEdit}>
        <MapPreview name={name} address={address} />
      </div>
    </div>
  );
}
