import { QRCode } from "../QRCode";
import { ChatInput } from "./ChatInput";
import { useState } from "react";
import arrowIcon from "./arrow.svg";
import { useDispatch } from "react-redux";
import { changeCardValues } from "../../../store/cards";

export const QRCodeContent = ({ id, questions }) => {
  const [newQuestion, setNewQuestion] = useState("");
  const [isActiveQR, setIsActiveQR] = useState(questions.length - 1);

  const dispatch = useDispatch();

  const onCreate = () => {
    const newList = [...questions, `${newQuestion}?`];
    dispatch(
      changeCardValues({
        id,
        values: { questions: newList },
      })
    );

    setIsActiveQR(newList.length - 1);
    setNewQuestion("");
  };

  return (
    <div className={"flex justify-center flex-col py-6 gap-3"}>
      <div className={"flex flex-col gap-2"}>
        {questions.map((question, index) => {
          return (
            <div key={question} className={"gap-2 flex flex-col"}>
              <div
                className={
                  "py-2.5 px-4 bg-[#F2F2F4] border-[#BABABA] border-[1.5px] rounded-xl max-w-[80%] self-end"
                }
              >
                {question}
              </div>
              <div
                className={
                  "bg-gradient-to-r from-[#7030A0] to-[#FF934E] overflow-hidden rounded-xl w-fit p-0.5"
                }
              >
                <ControlledQRCode
                  question={question}
                  isOpen={isActiveQR === index}
                  onClick={() => {
                    setIsActiveQR(index);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
      <ChatInput
        key={"chat-input"}
        question={newQuestion}
        onChange={setNewQuestion}
        onCreate={onCreate}
      />
    </div>
  );
};

const ControlledQRCode = ({ isOpen, question, onClick }) => {
  const text = `AI:Q:${question};;`;

  return isOpen ? (
    <div className={"bg-white text-center rounded-xl p-0.5 pb-4"}>
      <QRCode text={text} className={"rounded-xl"} />
      Scan to ask AI Assistant
    </div>
  ) : (
    <div
      className={
        "bg-white text-black/[.66] py-3 px-4 rounded-xl flex gap-4 cursor-pointer"
      }
      onClick={onClick}
    >
      Tap to view QR code
      <img src={arrowIcon} alt={"arrow"} />
    </div>
  );
};
