export const Preview = ({ onEdit, networkName, password }) => {
  return (
    <div
      onClick={onEdit}
      className={
        "cursor-pointer flex flex-col gap-6 hover:bg-gray-100 transition-colors"
      }
    >
      <div className={""}>
        <div className={"text-sm text-black/[66%]"}>Network Name</div>
        <div className={"text-base text-black break-all"}>{networkName}</div>
      </div>
      {password.length > 0 && <div className={""}>
        <div className={"text-sm text-black/[66%]"}>Password</div>
        <div className={"text-base text-black break-all"}>
          {[].map.call(password, () => "*")}
        </div>
      </div>}
    </div>
  );
};
