// TODO: translate the names of languages
export const LANGUAGES = {
  autodetect: "Autodetect",
  bg: "Bulgarian",
  cs: "Czech",
  da: "Danish",
  de: "Deutsch",
  nl: "Dutch",
  el: "Greek",
  en: "English",
  es: "Español",
  et: "Estonian",
  fi: "Finnish",
  fr: "Français",
  hu: "Hungarian",
  id: "Indonesian",
  it: "Italian",
  ja: "Japanese",
  ko: "Korean",
  lt: "Lithuanian",
  lv: "Latvian",
  no: "Norwegian",
  pl: "Polish",
  pt: "Portuguese",
  ro: "Romanian",
  ru: "Russian",
  sk: "Slovak",
  sl: "Slovenian",
  sv: "Swedish",
  tr: "Turkish",
  uk: "Ukrainian",
  "zh-CN": "中國人",
};
export function LanguageSelect({ value, onChange, autoFocus, filter }) {
  return (
    <select
      autoFocus={autoFocus}
      onChange={(e) => onChange(e.target.value)}
      value={value}
      className={
        "border-[1.5px] border-[#BABABA] rounded-xl h-12 px-4 py-2.5 text-base w-full"
      }
    >
      {Object.entries(filter(LANGUAGES)).map(([key, value]) => (
        <option key={key} value={key}>
          {value}
        </option>
      ))}
    </select>
  );
}
