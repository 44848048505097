import { CardInput } from "../inputs/CardInput";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { SpeechToVoiceButton } from "../inputs/SpeechToVoiceButton";
import { ChatInput } from "./ChatInput";

export const EditContent = forwardRef(
  ({ questions = "", onSubmit, onValidChange }, ref) => {
    const [question, setQuestion] = useState(questions[0] ?? "");

    useEffect(() => {
      if (question) {
        onValidChange(true);
      } else {
        onValidChange(false);
      }
    }, [question]);

    useImperativeHandle(
      ref,
      () => ({
        getValues() {
          return {
            questions: [`${question}?`],
          };
        },
      }),
      [question]
    );

    const onChange = (value) => {
      setQuestion(value);
    };

    return (
      <div className={"flex flex-col gap-6 py-6"}>
        <div className={"flex flex-col text-center gap-2"}>
          <div>Begin a chat by asking a question. Try:</div>
          <div className={"text-[#BABABA]"}>
            “What are some good thai restaurants nearby?”
          </div>
          <div className={"text-[#BABABA]"}>“How do I repot a plant?”</div>
          <div className={"text-[#BABABA]"}>
            “How to stay active while working a desk job?”
          </div>
        </div>
        <ChatInput question={question} onChange={onChange} required />
      </div>
    );
  }
);
