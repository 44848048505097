import { WiFiCard } from "../cards/WiFiCard";
import { TranslationCard } from "../cards/TranslationCard";
import { NavigateCard } from "../cards/NavigateCard";
import { ChatCard } from "../cards/ChatCard";
import { useMemo } from "react";

const isMobile = window.innerWidth < 768;

export function CardList({ children = [] }) {
  const [firstColumn, secondColumn] = useMemo(() => {
    const firstColumn = [];
    const secondColumn = [];

    const cards = children.map(({ id, type, values, editedDate, saved }) => {
      switch (type) {
        case "wifi": {
          return (
            <WiFiCard
              id={id}
              saved={saved}
              networkName={values.networkName}
              password={values.password}
              encryption={values.encryption}
            />
          );
        }
        case "translation": {
          return (
            <TranslationCard
              id={id}
              saved={saved}
              firstLanguage={values.firstLanguage}
              secondLanguage={values.secondLanguage}
            />
          );
        }
        case "navigation": {
          return (
            <NavigateCard
              id={id}
              saved={saved}
              query={values.query}
              name={values.name}
              featureType={values.featureType}
              fullAddress={values.fullAddress}
              coords={values.coords}
              transportationMode={values.transportationMode}
              address={values.address}
            />
          );
        }
        case "chat": {
          return (
            <ChatCard
              id={id}
              saved={saved}
              editedDate={editedDate}
              questions={values.questions}
            />
          );
        }
        default: {
          return <div></div>;
        }
      }
    });

    cards.forEach((child, index) => {
      if (isMobile) {
        firstColumn.push(child);
        return;
      }

      if (index % 2 === 0) {
        firstColumn.push(child);
      } else {
        secondColumn.push(child);
      }
    });

    return [firstColumn, secondColumn];
  }, [children]);

  return (
    <div className={"flex gap-6 flex-col md:flex-row"}>
      <ul className={"flex flex-col gap-6 w-full"}>
        {firstColumn.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <ul className={"flex flex-col gap-6 w-full"}>
        {secondColumn.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
}
