import qrcode from "qrcode";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";

export const QRCode = forwardRef(({ text, className }, ref) => {
  const canvasRef = useRef();

  useImperativeHandle(
    ref,
    () => ({
      shareQRCode() {
        canvasRef.current?.toBlob((blob) =>
          window.open(URL.createObjectURL(blob), "_blank")
        );
      },
    }),
    []
  );

  useEffect(() => {
    qrcode.toCanvas(canvasRef.current, text, function (error) {
      if (error) console.error(error);
      console.log("QRCode", text);
    });
  }, [text]);

  return (
    <canvas className={`w-[18rem] h-[18rem] ${className}`} ref={canvasRef} />
  );
});
