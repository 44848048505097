export function CardRadioButton({
  value,
  label = "",
  checked = false,
  onChange,
}) {
  return (
    <label className={"flex items-center cursor-pointer"}>
      <input
        onChange={(e) => onChange(value)}
        type={"radio"}
        className={"mr-2"}
        checked={checked}
      />
      <span className={"text-sm text-black"}>{label}</span>
    </label>
  );
}
