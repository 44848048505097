import { useSelector } from "react-redux";
import { useEffect } from "react";

export const useActiveQRCode = (cardId, onChange) => {
  const activeQRCodeId = useSelector((state) => state.cards.activeQRCodeId);

  useEffect(() => {
    if (activeQRCodeId === cardId) {
      onChange(true);
    } else {
      onChange(false);
    }
  }, [activeQRCodeId, cardId, onChange]);
};
