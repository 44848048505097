import { SpeechToVoiceButton } from "../inputs/SpeechToVoiceButton";
import sendIcon from "./send.svg";
import { CardTextarea } from "../inputs/CardTextarea";

const MIN_LENGTH = 5;

export const ChatInput = ({ question, onChange, onCreate, required }) => {
  return (
    <div className={"flex items-start gap-3"}>
      <div className={"flex-1 relative"}>
        <CardTextarea
          required={required}
          autoFocus
          maxLength={128}
          minLength={MIN_LENGTH}
          placeholder={"Search"}
          value={question}
          onChange={onChange}
        />
        {onCreate && (
          <div
            className={"absolute right-4 top-3 cursor-pointer"}
            onClick={() => {
              if (question.length >= MIN_LENGTH) {
                onCreate();
              }
            }}
          >
            <img src={sendIcon} alt={"Send"} />
          </div>
        )}
      </div>
      <SpeechToVoiceButton
        onChange={(value) => {
          onChange(value);
        }}
      />
    </div>
  );
};
