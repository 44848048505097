import { Card } from "../Card";
import { PassiveContent } from "./PassiveContent";
import { EditContent } from "./EditContent";
import { QRCodeContent } from "./QRCodeContent";
import iconSrc from "../../../icons/navigate.svg";
import { useGeolocation } from "./hooks";

export function NavigateCard({
  id,
  query,
  transportationMode,
  coords,
  fullAddress,
  featureType,
  saved,
  name,
  address,
}) {
  useGeolocation();

  return (
    <Card
      title={"Navigate"}
      QRCodeContent={QRCodeContent}
      iconSrc={iconSrc}
      isEditButtonVisible={false}
      saved={saved}
      isShare
      featureType={featureType}
      EditContent={EditContent}
      isNeedEdit={!query || !transportationMode || !coords || !address}
      PassiveContent={PassiveContent}
      id={id}
      fullAddress={fullAddress}
      name={name}
      query={query}
      transportationMode={transportationMode}
      coords={coords}
      address={address}
    />
  );
}
