import { Card } from "../Card";
import { PassiveContent } from "./PassiveContent";
import { EditContent } from "./EditContent";
import { QRCodeContent } from "./QRCodeContent";
import iconSrc from "../../../icons/wifi.svg";

export function WiFiCard({ id, networkName, password, encryption, saved }) {
  return (
    <Card
      id={id}
      saved={saved}
      EditContent={EditContent}
      QRCodeContent={QRCodeContent}
      PassiveContent={PassiveContent}
      iconSrc={iconSrc}
      isShare
      isNeedEdit={!networkName || !password || !encryption}
      networkName={networkName}
      password={password}
      encryption={encryption}
      title={"Wi-Fi"}
    />
  );
}
