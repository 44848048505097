import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useSuggestions } from "./hooks";
import axios from "axios";
import { SpeechToVoiceButton } from "../inputs/SpeechToVoiceButton";
import { CardTextarea } from "../inputs/CardTextarea";

export const EditContent = forwardRef(
  (
    {
      query: initialQuery = "",
      name: initialName = "",
      transportationMode: initialTransportationMode,
      coords,
      featureType,
      address: initialAddress,
      fullAddress: initialFullAddress,
      onSubmit,
    },
    ref
  ) => {
    const containerRef = useRef();
    const [query, setQuery] = useState(initialQuery);
    const valuesRef = useRef({
      featureType,
      name: initialName,
      lat: coords.lat,
      lng: coords.lng,
      fullAddress: initialFullAddress,
      address: initialAddress,
      transportationMode: initialTransportationMode ?? "C",
    });

    const {
      suggestions,
      handleSuggestions,
      resetSuggestions,
      sessionId,
      resetSession,
      isLoading,
    } = useSuggestions(containerRef);

    useImperativeHandle(
      ref,
      () => ({
        getValues() {
          return {
            query,
            coords: {
              lat: valuesRef.current.lat,
              lng: valuesRef.current.lng,
            },
            name: valuesRef.current.name,
            fullAddress: valuesRef.current.fullAddress,
            featureType: valuesRef.current.featureType,
            transportationMode: valuesRef.current.transportationMode,
            address: valuesRef.current.address,
          };
        },
      }),
      [query]
    );

    const onChange = (value) => {
      setQuery(value);
      resetSuggestions();
      handleSuggestions(value);
    };

    const onSuggestionClick = (suggestion) => {
      const value = `${suggestion.name} ${suggestion.queryAddress}`;
      setQuery(value);
      resetSuggestions();
      resetSession();

      valuesRef.current.name = suggestion.name;
      valuesRef.current.address = suggestion.queryAddress;
      valuesRef.current.fullAddress = suggestion.fullAddress;
      valuesRef.current.featureType = suggestion.featureType;

      axios(
        `/retrieve-suggestion?id=${suggestion.id}&sessionId=${sessionId}`
      ).then((result) => {
        if (result.data.features) {
          const { latitude, longitude } =
            result.data.features[0].properties.coordinates;

          valuesRef.current.lat = latitude;
          valuesRef.current.lng = longitude;

          onSubmit();
        }
      });
    };

    const suggestionsElements = suggestions.length > 0 && (
      <ul
        className={"flex flex-col shadow-md py-4 px-2 rounded bg-white w-full"}
      >
        {suggestions.map((suggestion, index, list) => (
          <div key={index}>
            <li
              onClick={() => onSuggestionClick(suggestion)}
              className={
                "flex flex-col gap-2 py-1 cursor-pointer hover:bg-gray-100 px-2"
              }
            >
              <div
                className={
                  "font-bold whitespace-nowrap text-ellipsis overflow-hidden"
                }
              >
                {suggestion.name}
              </div>
              <div
                title={suggestion.queryAddress}
                className={
                  "text-sm text-gray-500 whitespace-nowrap text-ellipsis overflow-hidden"
                }
              >
                {suggestion.queryAddress}
              </div>
            </li>
            {index !== list.length - 1 && <hr className={"border-gray-200"} />}
          </div>
        ))}
      </ul>
    );

    return (
      <div className={"flex flex-col py-6"} ref={containerRef}>
        <div className={"flex gap-3 items-start w-full"}>
          <div className={"flex-1 relative"}>
            <CardTextarea
              autoFocus
              required
              onClick={() => handleSuggestions(query)}
              maxLength={100}
              value={query}
              onChange={onChange}
              type={"text"}
              placeholder={"Type destination address or place name"}
            />
            {isLoading && (
              <div
                className={
                  "absolute top-16 shadow-md py-4 px-2 rounded bg-white w-full"
                }
              >
                <div className={"animate-pulse"}>Loading...</div>
              </div>
            )}
          </div>
          <SpeechToVoiceButton onChange={onChange} />
        </div>
        <div className={"w-full max-w-full relative z-20"}>
          <div className={"absolute top-0 w-full"}>{suggestionsElements}</div>
        </div>
      </div>
    );
  }
);
