export function AppSection({ title, iconSrc, children }) {
  return (
    <section className={"mb-12"}>
      <div className={"flex gap-3 justify-center mb-6"}>
        <img src={iconSrc} alt={"icon"} />
        <h2 className={"text-xl text-black/[.66]"}>{title}</h2>
      </div>
      {children}
    </section>
  );
}
