import { createSlice } from "@reduxjs/toolkit";
import { LANGUAGES } from "../components/cards/TranslationCard/LanguageSelect";

const initialState = {
  primaryCardId: null,
  activeQRCodeId: null,
  list: [],
};

function createId() {
  return Math.random().toString(36).substr(2, 9);
}

const isFinished = (card) => {
  switch (card?.type) {
    case "wifi": {
      return (
        card.values.networkName &&
        card.values.password &&
        card.values.encryption
      );
    }
    case "translation": {
      return card.values.firstLanguage && card.values.secondLanguage;
    }
    case "chat": {
      return (card.values.questions ?? []).length !== 0;
    }
    case "navigation": {
      return (
        card.values.query &&
        card.values.transportationMode &&
        card.values.coords &&
        card.values.address
      );
    }
    default: {
      return false;
    }
  }
};

function handlePrimaryCard(state, newId) {
  if (
    state.primaryCardId &&
    !isFinished(state.list.find((card) => card.id === state.primaryCardId))
  ) {
    state.list = state.list.filter((card) => card.id !== state.primaryCardId);
  }

  state.primaryCardId = newId;
}

export const cardsSlice = createSlice({
  name: "cards",
  initialState,
  reducers: {
    resetCards: (state) => {
      state.list = [];

      localStorage.removeItem("cards");
    },
    loadCachedCards: (state, action) => {
      const str = localStorage.getItem("cards");

      if (str) {
        try {
          state.list = JSON.parse(str).filter(
            (card) =>
              typeof card.type === "string" &&
              typeof card.values === "object" &&
              typeof card.saved === "boolean"
          );
        } catch (e) {
          console.error(e);
        }
      }
    },
    createWiFiCard: (state) => {
      const id = createId();

      handlePrimaryCard(state, id);
      const card = {
        id,
        type: "wifi",
        values: {
          networkName: "",
          password: "",
          encryption: "WPA",
        },
        saved: false,
      };

      state.list.unshift(card);
      state.activeQRCodeId = null;
    },
    createTranslationCard: (state) => {
      const id = createId();

      handlePrimaryCard(state, id);
      const defaultLanguage =
        navigator.languages.find((lang) => !!LANGUAGES[lang]) ?? "en";

      const card = {
        id,
        type: "translation",
        values: {
          firstLanguage: null,
          secondLanguage: defaultLanguage,
        },
        saved: false,
      };

      state.list.unshift(card);
      state.activeQRCodeId = null;
    },
    createChatCard: (state) => {
      const id = createId();

      handlePrimaryCard(state, id);
      const card = {
        id,
        type: "chat",
        values: {
          questions: [],
        },
        editedDate: new Date().toISOString(),
        saved: false,
      };

      state.list.unshift(card);
      state.activeQRCodeId = null;
    },
    createNavigationCard: (state) => {
      const id = createId();

      handlePrimaryCard(state, id);
      const card = {
        id,
        type: "navigation",
        values: {
          query: ``,
          name: ``,
          coords: {
            lat: 0,
            lng: 0,
          },
          transportationMode: "W",
          address: "",
        },
        saved: false,
      };

      state.list.unshift(card);
      state.activeQRCodeId = null;
    },
    changeSavedStatus: (state, action) => {
      const { id, isSaved } = action.payload;
      const card = state.list.find((card) => card.id === id);

      if (!card) {
        return;
      }

      card.saved = isSaved;

      if (isFinished(card)) {
        localStorage.setItem("cards", JSON.stringify(state.list));
      }
    },
    changeCardOneValue: (state, action) => {
      const { id, key, value } = action.payload;
      const card = state.list.find((card) => card.id === id);

      if (!card) {
        return;
      }

      card.values[key] = value;
      card.editedDate = new Date().toISOString();

      if (isFinished(card)) {
        localStorage.setItem("cards", JSON.stringify(state.list));
      }
    },
    changeCardValues: (state, action) => {
      const { id, values } = action.payload;
      const card = state.list.find((card) => card.id === id);

      if (!card) {
        return;
      }

      card.values = values;
      card.editedDate = new Date().toISOString();

      if (isFinished(card)) {
        localStorage.setItem("cards", JSON.stringify(state.list));
      }
    },
    makeQRCodeActive: (state, action) => {
      state.activeQRCodeId = action.payload;
    },
    resetQRCodeActive: (state) => {
      state.activeQRCodeId = null;
    },
  },
});

export const {
  createWiFiCard,
  createChatCard,
  createTranslationCard,
  createNavigationCard,
  changeCardValues,
  resetCards,
  makeQRCodeActive,
  resetQRCodeActive,
  loadCachedCards,
  changeSavedStatus,
  changeCardOneValue,
} = cardsSlice.actions;
export default cardsSlice.reducer;
