import { Card } from "../Card";
import { PassiveContent } from "./PassiveContent";
import { EditContent } from "./EditContent";
import { QRCodeContent } from "./QRCodeContent";
import iconSrc from "../../../icons/translate.svg";

export function TranslationCard({ id, firstLanguage, secondLanguage, saved }) {
  return (
    <Card
      EditContent={EditContent}
      QRCodeContent={QRCodeContent}
      PassiveContent={PassiveContent}
      iconSrc={iconSrc}
      saved={saved}
      isShare
      isNeedEdit={!firstLanguage || !secondLanguage}
      title={"Translate"}
      id={id}
      firstLanguage={firstLanguage}
      secondLanguage={secondLanguage}
    />
  );
}
