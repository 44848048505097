export function CardButton({ children, className, onClick, type, disabled }) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type={type}
      className={`h-full flex items-center ${
        disabled
          ? "bg-gray-100 border-gray-300 text-gray-600"
          : "border-[#7030A0]"
      } border-[1.5px] justify-center rounded-xl px-3 py-3 ${className}`}
    >
      {children}
    </button>
  );
}
