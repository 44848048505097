import { QRCode } from "../QRCode";
import { Preview } from "./Preview";
import { forwardRef, useImperativeHandle, useRef } from "react";

export const QRCodeContent = forwardRef(
  ({ networkName, password, encryption, onEdit }, ref) => {
    const qrCodeRef = useRef();
    useImperativeHandle(
      ref,
      () => ({
        shareQRCode() {
          qrCodeRef.current?.shareQRCode();
        },
      }),
      []
    );

    const text = `WIFI:S:${networkName};T:${encryption};P:${password};;`;

    return (
      <div className={"flex justify-center flex-col pt-6"}>
        <Preview
          networkName={networkName}
          password={password}
          onEdit={onEdit}
        />
        <div className={"flex items-center justify-center w-full"}>
          <QRCode ref={qrCodeRef} text={text} />
        </div>
      </div>
    );
  }
);
