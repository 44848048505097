import { QRCode } from "../QRCode";
import { MapPreview } from "./MapPreview";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import walkIcon from "./walk.svg";
import carIcon from "./car.svg";
import busIcon from "./bus.svg";
import bikeIcon from "./bike.svg";
import { useDispatch } from "react-redux";
import { changeCardOneValue } from "../../../store/cards";

const TRANSPORTATION_MODES = [
  {
    type: "walk",
    value: "W",
    icon: walkIcon,
  },
  {
    type: "bicycle",
    value: "B",
    icon: bikeIcon,
  },
  {
    type: "car",
    value: "C",
    icon: carIcon,
  },
  {
    type: "public",
    value: "P",
    icon: busIcon,
  },
];

export const QRCodeContent = forwardRef(
  (
    {
      name,
      id,
      coords,
      address,
      fullAddress,
      featureType,
      transportationMode: initialTransportationMode,
      onEdit,
    },
    ref
  ) => {
    const qrCodeRef = useRef();
    useImperativeHandle(
      ref,
      () => ({
        shareQRCode() {
          qrCodeRef.current?.shareQRCode();
        },
      }),
      []
    );
    const [transportationMode, setTransportationMode] = useState(
      initialTransportationMode ?? TRANSPORTATION_MODES[0].value
    );
    const dispatch = useDispatch();

    const addressValue = fullAddress ? `A:${fullAddress};` : "";

    const text = `NAV:C:${coords.lat},${coords.lng};M:${transportationMode};N:${name};${addressValue}T:${featureType};;`;

    return (
      <div className={"flex justify-center flex-col items-center py-6 gap-6"}>
        <div className={"w-full cursor-pointer"} onClick={onEdit}>
          <MapPreview name={name} address={address} withIcon />
        </div>
        <ul
          className={
            "flex border-[#7030A0] border-[1.5px] rounded-xl h-11 w-full overflow-hidden"
          }
        >
          {TRANSPORTATION_MODES.map((mode) => (
            <li
              className={`[&:not(:last-child)]:border-r-[1.5px] border-[#7030A0] cursor-pointer h-full w-1/4 flex gap-1 justify-center items-center ${
                mode.value === transportationMode ? "bg-[#FF934E]/[.33]" : ""
              }`}
              key={mode.value}
              onClick={() => {
                setTransportationMode(mode.value);
                dispatch(
                  changeCardOneValue({
                    id,
                    key: "transportationMode",
                    value: mode.value,
                  })
                );
              }}
            >
              <img src={mode.icon} alt={"icon"} />
              <div className={"hidden lg:block"}>{mode.type}</div>
            </li>
          ))}
        </ul>
        <QRCode ref={qrCodeRef} text={text} />
      </div>
    );
  }
);
