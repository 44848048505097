import { Card } from "../Card";
import { PassiveContent } from "./PassiveContent";
import { EditContent } from "./EditContent";
import { QRCodeContent } from "./QRCodeContent";
import iconSrc from "../../../icons/chat.svg";

export function ChatCard({ id, questions, editedDate, saved }) {
  return (
    <Card
      title={"Chat"}
      editedDate={editedDate}
      saved={saved}
      isNeedEdit={(questions ?? []).length === 0}
      iconSrc={iconSrc}
      QRCodeContent={QRCodeContent}
      EditContent={EditContent}
      PassiveContent={PassiveContent}
      id={id}
      questions={questions}
    />
  );
}
