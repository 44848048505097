import { useCallback, useEffect, useRef, useState } from "react";
import { useClickAway } from "react-use";
import axios from "axios";
import debounce from "lodash/debounce";

const DEBOUNCE_TIME = 500;

function generateId() {
  return Math.random().toString(36).substr(2, 9);
}

export const useSuggestions = (containerRef) => {
  const [suggestions, setSuggestions] = useState([]);
  const suggestionRef = useRef("");
  const [sessionId, setSessionId] = useState(generateId);
  const [isLoading, setIsLoading] = useState(false);

  const resetSuggestions = useCallback(() => {
    suggestionRef.current = "";
    setSuggestions([]);
  }, [setSuggestions]);

  useClickAway(containerRef, resetSuggestions);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSuggestions = useCallback(
    debounce((value) => {
      if (value === suggestionRef.current || value.length < 2) return;

      const lang = navigator.languages
          ? navigator.languages.map(lang => lang.split("-")[0]).join(",")
          : navigator.language.split("-")[0];
      const lat = sessionStorage.getItem("lat");
      const lng = sessionStorage.getItem("lng");

      suggestionRef.current = value;
      setIsLoading(true);

      const proximity = lat && lng ? `&lat=${lat}&lng=${lng}` : "";

      axios({
        url: `/suggestions?q=${value}&sessionId=${sessionId}&lang=${lang}${proximity}`,
      })
        .then((res) => {
          if (res.status !== 200) {
            console.error(`Suggestions API failed with error ${res.status} and the following contents:`);
            console.error(JSON.stringify(res.data));
          } else if (!res.data.suggestions) {
            console.error('There is no `suggestions` field in the server response!');
            console.error(JSON.stringify(res.data));
          } else {
            setSuggestions(
              res.data.suggestions.map((suggestion) => {
                const queryAddress =
                  suggestion.feature_type === "address"
                    ? suggestion.place_formatted
                    : suggestion.full_address ||
                      `${suggestion.place_formatted} ${suggestion.address}`;

                const fullAddress = suggestion.full_address;

                return {
                  id: suggestion.mapbox_id,
                  name: suggestion.name,
                  featureType: suggestion.feature_type,
                  queryAddress,
                  fullAddress,
                };
              })
            );
          }
        })
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    }, DEBOUNCE_TIME),
    [setIsLoading]
  );

  const resetSession = useCallback(() => {
    setSessionId(generateId());
  }, []);

  return {
    suggestions,
    handleSuggestions,
    resetSuggestions,
    sessionId,
    resetSession,
    isLoading,
  };
};

export const useGeolocation = () => {
  useEffect(() => {
    if (sessionStorage.getItem("askedForGeolocation")) return;

    sessionStorage.setItem("askedForGeolocation", "true");

    navigator.geolocation.getCurrentPosition(
      (geo) => {
        const { longitude, latitude } = geo.coords;

        sessionStorage.setItem("lat", latitude.toString());
        sessionStorage.setItem("lng", longitude.toString());
      },
      (error) => {
        console.error(error);
        axios("https://location.ostloong.workers.dev/")
          .then((res) => {
            const { longitude, latitude } = res.data;
            sessionStorage.setItem("lat", latitude.toString());
            sessionStorage.setItem("lng", longitude.toString());
          })
          .catch((err) => {
            console.error(err);
          });
      }
    );
  }, []);
};
