import { format } from "date-fns";

export function PassiveContent({ questions, onQRCode, editedDate }) {
  return (
    <div className={"flex flex-col gap-6 py-6"}>
      <div
        className={"cursor-pointer hover:bg-gray-100 transition-colors"}
        onClick={onQRCode}
      >
        <div className={"text-sm text-black/[66%]"}>
          {format(Date.parse(editedDate), "MMM d yyyy, h:mm a")}
        </div>
        <div className={"text-base text-black break-all"}>
          {questions[questions.length - 1]}
        </div>
      </div>
    </div>
  );
}
