import lyraLogo from "../../icons/logo.svg";
import deleteLogo from "../../icons/delete.svg";
import { resetCards } from "../../store/cards";
import { useDispatch } from "react-redux";

export function AppHeader() {
  const dispatch = useDispatch();
  return (
    <header
      className={
        "flex justify-center items-center h-24 md:h-28 border-b-[1.5px] border-black/[.2] relative"
      }
    >
      <img className={"h-8"} src={lyraLogo} alt={"logo"} />
      <img
        onClick={() => {
          dispatch(resetCards());
        }}
        className={"absolute right-5 md:right-20 cursor-pointer w-6 h-6"}
        src={deleteLogo}
        alt={"delete"}
      />
    </header>
  );
}
