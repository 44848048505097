import { CreateSection } from "./components/CreateSection";
import { AppHeader } from "./components/AppHeader";
import { SavedSection } from "./components/SavedSection";
import { RecentSection } from "./components/RecentSection";
import { useEffect } from "react";
import { loadCachedCards } from "./store/cards";
import { useDispatch } from "react-redux";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadCachedCards());
  }, [dispatch]);
  return (
    <div className="h-full">
      <AppHeader />
      <main className={"max-w-screen-lg px-5 pt-7 mx-auto pb-20"}>
        <CreateSection />
        <SavedSection />
        <RecentSection />
      </main>
    </div>
  );
}

export default App;
