import { LANGUAGES } from "./LanguageSelect";
import iconSrc from "../../../icons/swap.svg";

export function PassiveContent({ firstLanguage, secondLanguage, onQRCode }) {
  return (
    <div className={"py-6"}>
      <div
        onClick={onQRCode}
        className={
          "cursor-pointer flex flex-row justify-around gap-6 hover:bg-gray-100 transition-colors"
        }
      >
        <div className={""}>{LANGUAGES[firstLanguage]}</div>
        <div className={""}>
          <img className={"w-6 h-6"} src={iconSrc} alt={"swap"} />
        </div>
        <div className={""}>{LANGUAGES[secondLanguage]}</div>
      </div>
    </div>
  );
}
