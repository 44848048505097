import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { LanguageControls } from "./LanguageControls";

export const EditContent = forwardRef(
  (
    {
      id,
      firstLanguage: initialFirstLanguage,
      secondLanguage: initialSecondLanguage,
      onValidChange,
    },
    ref
  ) => {
    const [firstLanguage, setFirstLanguage] = useState(
      initialFirstLanguage ?? "autodetect"
    );
    const [secondLanguage, setSecondLanguage] = useState(initialSecondLanguage);

    useEffect(() => {
      onValidChange(true);
    }, [onValidChange]);

    useImperativeHandle(
      ref,
      () => ({
        getValues() {
          return {
            firstLanguage,
            secondLanguage,
          };
        },
      }),
      [firstLanguage, secondLanguage]
    );

    return (
      <div className={"flex flex-col gap-6 py-6"}>
        <div className={""}>
          <LanguageControls
            firstLanguage={firstLanguage}
            onChangeFirstLanguage={setFirstLanguage}
            onChangeSecondLanguage={setSecondLanguage}
            secondLanguage={secondLanguage}
          />
        </div>
      </div>
    );
  }
);
