export function CardTextarea({
  value,
  placeholder,
  onChange,
  type,
  autoFocus,
  onClick,
  maxLength = 50,
  required,
}) {
  return (
    <textarea
      value={value}
      autoFocus={autoFocus}
      onClick={onClick}
      autoComplete="off"
      required={required}
      maxLength={maxLength}
      onChange={(e) => onChange(e.target.value)}
      className={
        "border-[1.5px] border-[#BABABA] rounded-xl min-h-10 px-4 py-2.5 text-base w-full"
      }
      placeholder={placeholder}
    ></textarea>
  );
}
