import { useDispatch, useSelector } from "react-redux";
import {
  createWiFiCard,
  createChatCard,
  createNavigationCard,
  createTranslationCard,
} from "../../store/cards";
import { AppSection } from "../AppSection";
import translateIconSrc from "../../icons/translate-thin.svg";
import chatIconSrc from "../../icons/chat-thin.svg";
import navigationIconSrc from "../../icons/navigate-thin.svg";
import wifiIconSrc from "../../icons/wifi-thin.svg";
import iconSrc from "../../icons/plus.svg";
import { WiFiCard } from "../cards/WiFiCard";
import { TranslationCard } from "../cards/TranslationCard";
import { NavigateCard } from "../cards/NavigateCard";
import { ChatCard } from "../cards/ChatCard";

const BUTTONS = [
  {
    title: "Translation",
    action: createTranslationCard,
    iconSrc: translateIconSrc,
  },
  {
    title: "Chat",
    action: createChatCard,
    iconSrc: chatIconSrc,
  },
  {
    title: "Navigation",
    action: createNavigationCard,
    iconSrc: navigationIconSrc,
  },
  {
    title: "Wi-Fi",
    action: createWiFiCard,
    iconSrc: wifiIconSrc,
  },
];

export function CreateSection() {
  const dispatch = useDispatch();
  const card = useSelector((state) => {
    return state.cards.list.find(
      (card) => card.id === state.cards.primaryCardId
    );
  });

  let cardElement = null;

  if (card) {
    switch (card.type) {
      case "wifi": {
        cardElement = (
          <WiFiCard
            id={card.id}
            saved={card.saved}
            networkName={card.values.networkName}
            password={card.values.password}
            encryption={card.values.encryption}
          />
        );
        break;
      }
      case "translation": {
        cardElement = (
          <TranslationCard
            id={card.id}
            saved={card.saved}
            firstLanguage={card.values.firstLanguage}
            secondLanguage={card.values.secondLanguage}
          />
        );
        break;
      }
      case "navigation": {
        cardElement = (
          <NavigateCard
            id={card.id}
            saved={card.saved}
            query={card.values.query}
            name={card.values.name}
            featureType={card.values.featureType}
            coords={card.values.coords}
            fullAddress={card.values.fullAddress}
            transportationMode={card.values.transportationMode}
            address={card.values.address}
          />
        );
        break;
      }
      case "chat": {
        cardElement = (
          <ChatCard
            id={card.id}
            saved={card.saved}
            questions={card.values.questions}
            editedDate={card.editedDate}
          />
        );
        break;
      }
      default: {
        return <div></div>;
      }
    }
  }

  return (
    <AppSection title={"Create"} iconSrc={iconSrc}>
      <ul
        className={
          "grid grid-cols-2 md:grid-cols-4 grid-rows-2 md:grid-rows-1 gap-4"
        }
      >
        {BUTTONS.map((button, index) => (
          <li className={"w-full"} key={index}>
            <button
              className="bg-white rounded-2xl px-4 pt-4 pb-5 shadow onClick={button.action} w-full h-full flex flex-col items-center gap-2"
              onClick={() => dispatch(button.action())}
            >
              <img className="w-9 h-9" src={button.iconSrc} alt={"icon"}></img>
              <div>{button.title}</div>
            </button>
          </li>
        ))}
      </ul>
      {card && <div className="flex flex-col mt-4">{cardElement}</div>}
    </AppSection>
  );
}
